.custom-toggler {
  background-color: #212529;
  color: #fff;
  border: none;
  font-size: 24px;
  padding: 5px;
}

.custom-toggler:active {
  background-color: white;
  color: #212529;
}

.custom-toggler:hover {
  background-color: #ffff;
  color: #212529;
  /* Change text color on hover */
}

.photo-slider-text {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 2;
  text-align: center;
  color: white;
}

.custom-input:focus {
  outline: none;
  box-shadow: 0 0 0 0.2rem rgba(0, 0, 0, 0.25);
  /* Dark shadow */
  border-color: #212529;
  /* Dark border color */
}

.profile-image {
  width: 70px;
  height: 100px;
  object-fit: cover;
  border-radius: 5px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

.image-container {
  display: flex;
  width: 100%;
  height: 100%;
  overflow: hidden;
}

.image-container img {
  display: block;
  object-fit: cover;
  width: 100%;
  height: 100%;
}

.hoverable {
  transition: transform 0.3s ease;
}

.hoverable:hover {
  transform: scale(1.02);
}

.search {
  display: none;
  position: relative;
  margin-right: 20px;
}

.magnifier {
  position: absolute;
  top: 50%;
  right: 10px;
  transform: translateY(-50%);
  color: #212529;
}

.search-box {
  display: flex;
  position: relative;
}

.search-container {
  position: relative;
  margin-right: 20px;
}

.search-container .search-box.open input[type="search"] {
  display: block;
  position: absolute;
  top: 0;
  right: 0;
  z-index: 1;
}

.search-btn {
  background-color: transparent;
  border: none;
  outline: none;
  cursor: pointer;
  font-size: 18px;
  color: #ffff;
  /* Adjust the color as needed */
}

.search-btn:focus {
  outline: none;
}

.search-btn:hover {
  color: #000;
}

.back-btn {
  background-color: transparent;
  border: none;
  outline: none;
  cursor: pointer;
  font-size: 18px;
  color: #ffff;
  margin-right: 8px;
  position: absolute;
  top: 50%;
  left: 10px;
  transform: translateY(-50%);
  color: #212529;
}

.back-btn:focus {
  outline: none;
}

.back-btn:hover {
  color: #000;
  /* Adjust the color as needed */
}


@keyframes placeholderAnimation {
  0% {
    background-color: #f0f0f0;
  }

  50% {
    background-color: #e0e0e0;
  }

  100% {
    background-color: #f0f0f0;
  }
}

.image-placeholder {
  width: 100%;
  padding-top: 56.25%;
  position: relative;
  animation: placeholderAnimation 1s infinite;
}

.card-img-placeholder {
  background-color: #f5f5f5;
  height: 200px;
  position: relative;
  overflow: hidden;
}

.card-img-placeholder::after {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: #ddd;
  animation: placeholderAnimation 1s infinite;
}

.profile-placeholder {
  width: 70px;
  height: 100px;
  object-fit: cover;
  border-radius: 5px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  animation: placeholderAnimation 1s infinite;
}

@media (min-width: 885px) {
  .search {
    display: block;
  }

  .search-container {
    display: none;
  }

}

@media (max-width: 991px) {

  .justify-content-center .col-lg-9,
  .col-lg-3 {
    width: 100%;
  }
}